import { PlacementAxis } from 'react-aria'
import styled, { keyframes } from 'styled-components'

import { COLORS } from '../colors'
import { textSmall } from '../typography'

export const StyledArrow = styled.div`
  position: absolute;
  svg {
    min-width: 17px;
    fill: ${COLORS.N800};
  }
`

const slide = keyframes`
 from {
    transform: var(--origin);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`

export const StyledTooltip = styled.span<{ $placement?: PlacementAxis }>`
  ${textSmall};
  background: ${COLORS.N800};
  color: ${COLORS.WHITE};
  position: absolute;
  height: max-content;
  width: max-content;
  max-width: 200px;
  padding: 8px;
  border-radius: 4px;
  word-break: break-word;
  box-shadow:
    0px 1px 4px rgba(0, 0, 0, 0.1),
    0px 1px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000000;
  animation: ${slide} 0.2s;

  &[data-placement='top'] {
    --origin: translateY(4px);
    ${StyledArrow} {
      top: 100%;
      transform: translateX(-50%) translateY(-6px);
    }
  }

  &[data-placement='bottom'] {
    --origin: translateY(-4px);
    ${StyledArrow} {
      bottom: 100%;
      transform: translateX(-50%) translateY(5px);
    }
    svg {
      transform: rotate(180deg);
    }
  }

  &[data-placement='right'] {
    --origin: translateX(-4px);
    ${StyledArrow} {
      right: 100%;
      transform: translateY(-50%) translateX(5px);
    }
    svg {
      transform: rotate(90deg);
    }
  }

  &[data-placement='left'] {
    --origin: translateX(4px);
    ${StyledArrow} {
      left: 100%;
      transform: translateY(-50%) translateX(-5px);
    }
    svg {
      transform: rotate(-90deg);
    }
  }
`
