import { memo } from 'react'

import { HtTooltip, Size, TextBodySmall } from '../base'
import { HtIcon, Icon } from '../base'

interface BuildComponentTooltipProps {
  title: string
  tooltipText: string
}

export const BuildComponentTooltip = memo(
  ({ title, tooltipText }: BuildComponentTooltipProps) => {
    return (
      <HtTooltip content={tooltipText} placement='right'>
        <TextBodySmall
          style={{ display: 'flex', gap: 4, alignItems: 'center' }}
        >
          {title}
          <HtIcon icon={Icon.CIRCLE_QUESTION} size={Size.MICRO} />
        </TextBodySmall>
      </HtTooltip>
    )
  }
)
