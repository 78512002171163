import styled, { css, keyframes } from 'styled-components'

import { COLORS } from '../colors'
import { Size } from '../variants'

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dashoffset: -125px;
  }
`

export const StyledCircularLoader = styled.div<{
  $size: Size
  $intent: string
}>`
  display: flex;

  svg {
    transform-origin: center;
    animation: ${rotate} 2s linear infinite;
    ${props => props.$size === Size.LARGE && sizeLarge};
    ${props => props.$size === Size.MEDIUM && sizeMedium};
    ${props => props.$size === Size.SMALL && sizeSmall};
    ${props => props.$size === Size.TINY && sizeTiny};
  }

  circle {
    fill: none;
    stroke: ${props =>
      props.$intent === 'primary' ? COLORS.L500 : COLORS.N500};
    stroke-width: 3;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    animation: ${dash} 1.5s ease-in-out infinite;
  }
`

const sizeLarge = css`
  width: 28px;

  circle {
    stroke-width: 4;
  }
`

const sizeMedium = css`
  width: 24px;

  circle {
    stroke-width: 4;
  }
`

const sizeSmall = css`
  width: 16px;

  circle {
    stroke-width: 5;
  }
`

const sizeTiny = css`
  width: 14px;

  circle {
    stroke-width: 4;
  }
`
