import { memo, useEffect, useState } from 'react'

import { useBotConfig } from '../../custom-hooks/use-bot-config'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { ROLES } from '../../roles'
import {
  DropdownProps,
  HtBtnType,
  Placement,
  Size,
  TextBodyBold,
} from '../base'
import { HorizontalContainer } from '../base/containers'
import { AiSettingsDropdown } from './ai-settings/ai-settings-dropdown'
import { ConnectionStatus } from './connection-status/connection-status'
import { ExitButton } from './exit/exit-button'
import { FixedWidthContainer, HeaderContainer } from './header-styles'
import { LocaleDropdown } from './locale-dropdown/locale-dropdown'
import { PreviewButton } from './preview/preview-button'
import { PublishButton } from './publish/publish-button'
import { ErrorButton } from './save/errors-button'
import { SaveIndicator } from './save/save-indicator'
import { TransferFlowsButton } from './transfer-flows/transfer-flows-button'
import {
  VersionHistoryData,
  VersionHistoryDropdown,
} from './version-history-dropdown/version-history-dropdown'

export const headerDropdownProps: Omit<DropdownProps, 'label'> = {
  btnProps: {
    type: HtBtnType.BORDERLESS_BLACK,
    size: Size.SMALL,
    width: 'fit-content',
  },
  listProps: {
    maxListHeight: '450px',
    hasItemsWithBorder: true,
    itemSize: Size.LARGE,
    listPlacement: Placement.BOTTOM_END,
  },
  hideLabel: true,
}

export interface HeaderProps {
  loadPublishedVersion(version: VersionHistoryData): Promise<void>
}

export const Header = memo(
  ({ loadPublishedVersion }: HeaderProps): JSX.Element => {
    const { isPreviewEnabled } = useBotConfig()
    const { state } = useFlowBuilderSelector(ctx => ctx)
    const [hasFlowErrors, setHasFlowErrors] = useState(false)

    useEffect(() => {
      const hasErrors = state.nodes.some(
        node =>
          node.data.errors.showErrors &&
          (node.data.errors.fieldErrors.length ||
            node.data.errors.hasDuplicatedCode)
      )
      if (hasErrors && !state.isFlowSaved) return
      setHasFlowErrors(hasErrors)
    }, [state.nodes, state.isFlowSaved])

    return (
      <HeaderContainer role={ROLES.BANNER}>
        <TextBodyBold>
          {state.organizationContents.currentConversationalApp.name}
        </TextBodyBold>
        <HorizontalContainer $size={Size.LARGE}>
          <AiSettingsDropdown />
          <LocaleDropdown />
          <VersionHistoryDropdown loadPublishedVersion={loadPublishedVersion} />
        </HorizontalContainer>
        <HorizontalContainer $size={Size.SMALL} $align='center'>
          <FixedWidthContainer>
            <ConnectionStatus />
            <SaveIndicator hasFlowErrors={hasFlowErrors} />
            <ErrorButton hasFlowErrors={hasFlowErrors} />
          </FixedWidthContainer>
          {isPreviewEnabled && <PreviewButton />}
          <PublishButton hasFlowErrors={hasFlowErrors} />
        </HorizontalContainer>
        <HorizontalContainer $size={Size.SMALL} $align='center'>
          <TransferFlowsButton />
          <ExitButton />
        </HorizontalContainer>
      </HeaderContainer>
    )
  }
)
