import { Controller, UseFormReturn } from 'react-hook-form'

import { useFlowBuilderSelector } from '../../../../../reducer/hooks'
import {
  HtBtnType,
  HtCheckbox,
  TextBodyBig,
  TextBodySmallBold,
  TextSmall,
} from '../../../../base'
import { Banner } from '../../../../base/ht-banner/banner'
import { Popup } from '../../../../popup/popup'
import { TransferDetails } from '../transfer-flows-modal'
import { StyledTransferSummary } from '../transfer-flows-styles'

interface SummaryStepProps {
  form: UseFormReturn<TransferDetails, any, undefined>
  goBack: () => void
  transfer: () => void
}

export const SummaryStep = ({
  form,
  goBack,
  transfer,
}: SummaryStepProps): JSX.Element => {
  const { state } = useFlowBuilderSelector(ctx => ctx)

  return (
    <Popup
      title='Transfer flows'
      confirmButton={{
        text: 'Transfer',
        type: HtBtnType.DANGER,
        onClick: transfer,
      }}
      discardButton={{ text: 'Back', onClick: goBack }}
      canCloseOnClickOutside={false}
    >
      <StyledTransferSummary>
        <TextBodySmallBold>Transfer to</TextBodySmallBold>
        <TextBodyBig>{form.getValues('targetBot')?.name}</TextBodyBig>
        <TextSmall>
          Transferring {state.flows.length} Flows, {state.locales.length}{' '}
          Languages
        </TextSmall>
      </StyledTransferSummary>
      <Banner
        intent='warning'
        description='This action will replace destination bot’s flow. This action can’t be undone.'
      />
      <form id='source-type-form'>
        <Controller
          name='openTargetBotOnTransfer'
          control={form.control}
          render={({ field }) => (
            <HtCheckbox
              {...field}
              checked={field.value}
              label='Open the destination bot after transferring the flow.'
            />
          )}
        />
      </form>
    </Popup>
  )
}
