import styled from 'styled-components'

import { COLORS, Icon, TextSmall } from '../base'

export const HeaderContainer = styled.header`
  position: absolute;
  height: 46px;
  width: 100%;
  box-sizing: border-box;
  padding: 0px 16px;
  z-index: 25;
  background-color: ${COLORS.N50};
  color: ${COLORS.N900};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 64px;
  user-select: none;
  -webkit-user-select: none;
  border-left: 1px solid ${COLORS.N100};

  > :first-child {
    margin-right: auto;
  }
`

export const ConnectionStatusContainer = styled.div`
  height: 18px;
  width: 23px;

  svg {
    position: absolute;
  }

  [data-icon=${Icon.CLOUD_SLASH}],[data-icon=${Icon.CLOUD_CHECK}] {
    color: ${COLORS.N500} !important;
  }
`

export const SavedText = styled(TextSmall)`
  font-style: italic;
  margin: 0px 16px 0px 8px;
  color: ${COLORS.N500};
`

export const FixedWidthContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 120px;
`

export const StyledWaringButton = styled.div`
  button {
    height: 26px;
    width: 26px;
    align-items: center;
    justify-content: center;
  }
  svg {
    --fa-secondary-color: ${COLORS.O500};
    --fa-primary-color: ${COLORS.WHITE};
    --fa-secondary-opacity: 1;
    height: 14px;
  }
`
