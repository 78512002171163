import { useEffect, useState } from 'react'

import { TrackEventName, useAnalytics } from '../../../analytics'
import { useFlowBuilderSelector } from '../../../reducer/hooks'
import { PopupType } from '../../../types'
import { HtButton, HtTooltip, Size } from '../../base'

interface PublishButtonProps {
  hasFlowErrors: boolean
}

export const PublishButton = ({
  hasFlowErrors,
}: PublishButtonProps): JSX.Element => {
  const analytics = useAnalytics()
  const { state, setPopupContent } = useFlowBuilderSelector(ctx => ctx)
  const [isDisabled, setIsDisabled] = useState(true)
  const [tooltip, setTooltip] = useState<string>()

  useEffect(() => {
    if (state.isReadOnly || state.isOffline) {
      setTooltip(undefined)
      setIsDisabled(true)
    } else if (hasFlowErrors) {
      setTooltip('Fix errors to publish')
      setIsDisabled(true)
    } else if (state.hash === state.hashPublished) {
      setTooltip('Already published')
      setIsDisabled(true)
    } else {
      setTooltip(undefined)
      setIsDisabled(false)
    }
  }, [
    hasFlowErrors,
    state.hash,
    state.hashPublished,
    state.isReadOnly,
    state.isOffline,
  ])

  const openPublishModal = async (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.stopPropagation()
    evt.currentTarget.blur()
    setPopupContent({ type: PopupType.PUBLISH })
    await analytics.trackEvent(TrackEventName.CLICK_PUBLISH_CTA)
  }

  return (
    <HtTooltip content={tooltip} isDisabled={!tooltip}>
      <HtButton
        size={Size.TINY}
        disabled={isDisabled}
        onClick={openPublishModal}
      >
        Publish
      </HtButton>
    </HtTooltip>
  )
}
