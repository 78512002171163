import {
  DropdownData,
  HtBtnType,
  HtButton,
  HtIcon,
  HtTooltip,
  Icon,
  Size,
  TextBodySmall,
} from '../../../base'
import { StyledEditableItem } from './styles'

interface HtEditableDropdownItemProps<T extends DropdownData> {
  item: T
  canEdit?: boolean
  canRemove?: boolean
  onRemove: () => void
  onEdit?: () => void
}

export const HtEditableDropdownItem = <T extends DropdownData>({
  item,
  canEdit = false,
  canRemove = true,
  onRemove,
  onEdit,
}: HtEditableDropdownItemProps<T>) => {
  const isTooltipNeeded = Boolean(item.name && item.name.length > 100)
  return (
    <StyledEditableItem $isLongItem={isTooltipNeeded}>
      <HtTooltip
        content={item.name}
        isDisabled={!isTooltipNeeded}
        delay={500}
        placement='bottom left'
      >
        <TextBodySmall>{item.name || item.id}</TextBodySmall>
      </HtTooltip>
      <div>
        {canEdit && onEdit && (
          <HtButton
            type={HtBtnType.ICON_ONLY}
            size={Size.TINY}
            onPointerUp={evt => {
              evt.stopPropagation()
              onEdit()
            }}
          >
            <HtIcon icon={Icon.EDIT} />
          </HtButton>
        )}
        {canRemove && onRemove && (
          <HtButton
            type={HtBtnType.ICON_ONLY}
            size={Size.TINY}
            onPointerUp={evt => {
              evt.stopPropagation()
              onRemove()
            }}
          >
            <HtIcon icon={Icon.TRASH_CAN} />
          </HtButton>
        )}
      </div>
    </StyledEditableItem>
  )
}
