import React from 'react'
import {
  AriaSwitchProps,
  useFocusRing,
  useSwitch,
  VisuallyHidden,
} from 'react-aria'
import { useToggleState } from 'react-stately'

import { HelperText } from '../base-styles'
import { COLORS } from '../colors'
import { HtTooltip } from '../ht-tooltip/ht-tooltip'
import { TextBody } from '../typography'
import { Size } from '../variants'
import { StyledSwitch, SwitchContainer } from './styles'

interface HtSwitchProps extends AriaSwitchProps {
  helperText?: string
  tooltip?: string
  size?: Size.SMALL | Size.MEDIUM
}

export const HtSwitch = ({
  helperText,
  tooltip,
  size = Size.MEDIUM,
  ...props
}: HtSwitchProps) => {
  const state = useToggleState(props)
  const ref = React.useRef(null)
  const { inputProps } = useSwitch(props, state, ref)
  const { isFocusVisible, focusProps } = useFocusRing()

  return (
    <SwitchContainer>
      <HtTooltip isDisabled={!tooltip} content={tooltip}>
        <StyledSwitch $isDisabled={props.isDisabled} $size={size}>
          <VisuallyHidden>
            <input {...inputProps} {...focusProps} ref={ref} />
          </VisuallyHidden>
          <svg
            width={30}
            height={16}
            aria-hidden='true'
            style={{ marginRight: 4 }}
          >
            <rect
              width={30}
              height={16}
              rx={8}
              fill={state.isSelected ? COLORS.L500 : COLORS.N500}
            />
            <circle
              cx={state.isSelected ? 22 : 8}
              cy={8}
              r={6}
              fill={COLORS.WHITE}
            />
            {isFocusVisible && (
              <rect
                width={32}
                height={18}
                rx={9}
                fill='none'
                stroke='none'
                strokeWidth={1.5}
              />
            )}
          </svg>
          <TextBody>{props.children}</TextBody>
        </StyledSwitch>
      </HtTooltip>
      {helperText && <HelperText>{helperText}</HelperText>}
    </SwitchContainer>
  )
}
