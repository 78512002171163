import { AriaButtonProps, useButton } from 'react-aria'

import { HtBtnSize, HtBtnType } from '../../ht-button/ht-button'
import { HtIcon } from '../../ht-icon/ht-icons'
import { Icon } from '../../ht-icon/icons-list'
import { HtLoader } from '../../ht-loader/ht-loader'
import { HtTooltip } from '../../ht-tooltip/ht-tooltip'
import { Size } from '../../variants'
import { StyledDropdownButton } from './styles'

interface ButtonProps extends AriaButtonProps {
  buttonRef: React.RefObject<HTMLButtonElement>
  btnProps?: {
    type?: HtBtnType
    size?: HtBtnSize
    width?: string
    tooltip?: string
  }
  readOnly?: boolean
  isInvalid?: boolean
  isLoading?: boolean
  isOpen: boolean
  isEmpty?: boolean
  buttonSymbol?: React.ReactNode
}

export const DropdownButton = (props: ButtonProps): JSX.Element => {
  const ref = props.buttonRef
  const { buttonProps } = useButton(
    { ...props, isDisabled: props.readOnly || props.isLoading },
    ref
  )

  const getIcon = () => {
    if (props.isLoading) {
      return <HtLoader intent='secondary' size={Size.SMALL} />
    }
    return (
      <HtIcon
        icon={props.isOpen ? Icon.CHEVRON_UP : Icon.CHEVRON_DOWN}
        size={Size.SMALL}
      />
    )
  }

  return (
    <StyledDropdownButton
      {...buttonProps}
      ref={ref}
      $type={props.btnProps?.type || HtBtnType.TERTIARY}
      $size={props.btnProps?.size || Size.MEDIUM}
      $width={props.btnProps?.width || '100%'}
      $isEmpty={props.isEmpty}
      $isReadOnly={props.readOnly}
      $isInvalid={props.isInvalid}
      disabled={props.isDisabled || props.isLoading}
      aria-label={props.children?.toString()}
    >
      <div>
        {props.buttonSymbol}
        <HtTooltip
          content={props.btnProps?.tooltip}
          isDisabled={!props.btnProps?.tooltip}
          delay={500}
        >
          <span>{props.children}</span>
        </HtTooltip>
        {getIcon()}
      </div>
    </StyledDropdownButton>
  )
}
