import { TrackEventName, useAnalytics } from '../../analytics'
import { HtIcon, Icon, Size } from '../../components/base'
import { Popup } from '../../components/popup/popup'
import { postCloseFromFlowBuilderMessage } from '../../hubtype-events'
import { StyledSessionEndedTitle } from './session-styles'

export const ConnectionLostPopup = (): JSX.Element => {
  const analytics = useAnalytics()

  const close = (): void => {
    analytics.trackEvent(TrackEventName.USER_SESSION_ENDED)
    postCloseFromFlowBuilderMessage(null)
  }

  return (
    <Popup
      title={
        <StyledSessionEndedTitle>
          <HtIcon icon={Icon.CIRCLE_EXCLAMATION} size={Size.MEDIUM} />
          Disconnected: Connection lost
        </StyledSessionEndedTitle>
      }
      discardButton={{ text: 'Close', onClick: close }}
      message='Your session has ended due to a temporary connection issue.'
      canCloseOnClickOutside={false}
      closeButtonEnabled={false}
    />
  )
}
