import { FocusableElement } from '@react-types/shared'
import { DOMAttributes } from 'react'
import { PlacementAxis } from 'react-aria'

import { StyledArrow } from './ht-tooltip-styles'

interface ArrowProps extends DOMAttributes<FocusableElement> {
  placement: PlacementAxis
}

export const Arrow = ({ placement, ...props }: ArrowProps): JSX.Element => {
  return (
    <StyledArrow {...props}>
      <svg width={14} height={7} viewBox='0 0 18 10'>
        <path
          transform='translate(0 -1)'
          d='M1 1L7.93799 8.52588C8.07224 8.67448 8.23607 8.79362 8.41895 8.87524C8.60182 8.95687 8.79973 8.9993 9 9C9.19984 8.99882 9.39724 8.95606 9.57959 8.87427C9.76193 8.79248 9.9253 8.67336 10.0591 8.5249L17 1'
        />
      </svg>
    </StyledArrow>
  )
}
