import { useRef } from 'react'
import { HiddenSelect, useSelect } from 'react-aria'
import { SelectProps as AriaSelectProps, useSelectState } from 'react-stately'

import { HelperText } from '../../base-styles'
import { DropdownButton } from '../../common/dropdown/dropdown-button'
import { Popover } from '../../common/dropdown/popover'
import { DropdownContainer } from '../../common/dropdown/styles'
import { DropdownProps } from '../../common/dropdown/types'
import { HorizontalContainer } from '../../containers'
import { HtBtnType, HtButton } from '../../ht-button/ht-button'
import { HtIcon } from '../../ht-icon/ht-icons'
import { Icon } from '../../ht-icon/icons-list'
import { TextSmallBold } from '../../typography'
import { Size } from '../../variants'
import { ListBox } from './list-box'

export interface SelectProps<T extends object>
  extends Omit<AriaSelectProps<T>, 'label'>,
    DropdownProps {}

export const Select = <T extends object>(
  props: SelectProps<T>
): JSX.Element => {
  const state = useSelectState(props)
  const ref = useRef<HTMLButtonElement>(null)
  const { labelProps, triggerProps, menuProps } = useSelect(
    { ...props, disabledKeys: [], isDisabled: props.readOnly },
    state,
    ref
  )
  const buttonWidth =
    props.canBeRemoved && state.selectedItem
      ? 'calc(100% - 49px)'
      : props.btnProps?.width

  return (
    <DropdownContainer width={props.width} $isInvalid={props.isInvalid}>
      {!props.hideLabel && (
        <TextSmallBold {...labelProps}>{props.label}</TextSmallBold>
      )}
      <HiddenSelect state={state} triggerRef={ref} label={props.label} />
      <HorizontalContainer $size={Size.TINY}>
        <DropdownButton
          {...triggerProps}
          buttonRef={ref}
          btnProps={{ ...props.btnProps, width: buttonWidth }}
          readOnly={props.readOnly}
          isInvalid={props.isInvalid}
          isOpen={state.isOpen}
          isEmpty={!state.selectedItem?.textValue}
          buttonSymbol={props.buttonSymbol}
          isLoading={props.isLoading}
        >
          {(props.renderButtonAsItem
            ? state.selectedItem?.props.children
            : state.selectedItem?.textValue) || props.placeholder}
        </DropdownButton>
        {props.canBeRemoved && state.selectedItem && (
          <HtButton
            type={HtBtnType.TERTIARY}
            disabled={!props.selectedKey}
            onClick={() => state.setSelectedKey(null)}
          >
            <HtIcon icon={Icon.XMARK} />
          </HtButton>
        )}
      </HorizontalContainer>
      {state.isOpen && (
        <Popover state={state} triggerRef={ref} listProps={props.listProps}>
          {props.header}
          <ListBox
            {...menuProps}
            state={state}
            filterValue={props.filterValue}
          />
          {props.footer}
        </Popover>
      )}
      {props.helperText && <HelperText>{props.helperText}</HelperText>}
    </DropdownContainer>
  )
}
