import { AnalyticsBrowser } from '@segment/analytics-next'
import { useEffect, useState } from 'react'

import { FlowBuilderService } from '../repository/hubtype/flow-builder-service'
import { RepositoryProvider } from '../repository/repository-utils'
import { AnalyticsProvider, getAnalyticsWriteKey } from './analytics'
import { HtLoadingScreen } from './components/base'
import FlowBuilder from './flow-builder'
import { Container } from './flow-styles'
import {
  getFlowBuilderSettings,
  postFlowBuilderInitializedMessage,
} from './hubtype-events'
import { RealtimeProvider } from './realtime/realtime-provider'
import { useFlowBuilder } from './reducer/hooks'
import { FlowBuilderProvider } from './reducer/provider'
import { LoadingMessage } from './types'

export const FlowBuilderApp = (): JSX.Element => {
  const store = useFlowBuilder()
  const [analytics] = useState(() =>
    AnalyticsBrowser.load({ writeKey: getAnalyticsWriteKey() })
  )

  useEffect(() => {
    store.setLoadingMessage(LoadingMessage.LOADING_APP)
    const hasAuthToken = getAuthTokenFromURL()
    if (!hasAuthToken) {
      postFlowBuilderInitializedMessage()
      window.addEventListener('message', getAuthTokenFromSettings)
    }
  }, [])

  const getAuthTokenFromURL = (): boolean => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    return setAuthToken(Object.fromEntries(urlSearchParams))
  }

  const getAuthTokenFromSettings = (evt: MessageEvent) => {
    const data = getFlowBuilderSettings(evt)

    if (!data) return
    const hasAuthToken = setAuthToken(data)
    if (hasAuthToken) {
      window.removeEventListener('message', getAuthTokenFromSettings)
    }
  }

  const setAuthToken = (data: any): boolean => {
    if (!data.authToken) return false
    store.setAuthToken(data.authToken)
    return true
  }

  useEffect(() => {
    const setFlowBuilderUser = async () => {
      if (!store.state.authToken) return
      const user = await FlowBuilderService.getUserInfo(store.state.authToken)
      if (!user) return
      store.setFlowBuilderUser(user)
    }
    setFlowBuilderUser()
  }, [store.state.authToken])

  return (
    <Container height={'100vh'}>
      <RealtimeProvider authToken={store.state.authToken}>
        <AnalyticsProvider analytics={analytics}>
          {store.state.loadingMessage && (
            <HtLoadingScreen loadingMessage={store.state.loadingMessage} />
          )}
          {store.state.flowBuilderUser && (
            <RepositoryProvider>
              <FlowBuilderProvider store={store}>
                <FlowBuilder />
              </FlowBuilderProvider>
            </RepositoryProvider>
          )}
        </AnalyticsProvider>
      </RealtimeProvider>
    </Container>
  )
}
