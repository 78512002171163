import { PresenceMessage } from 'ably'
import { useState } from 'react'

import { TrackEventName, useAnalytics } from '../../analytics'
import {
  COLORS,
  TextBodySmall,
  TextSmall,
  TextSmallBold,
} from '../../components/base'
import { Popup } from '../../components/popup/popup'
import { postCloseFromFlowBuilderMessage } from '../../hubtype-events'
import { useFlowBuilderSelector } from '../../reducer/hooks'
import { PopupType } from '../../types'
import {
  StyledActiveUserInfoContainer,
  StyledEndSessionContainer,
  StyledSessionEndedContainer,
} from './session-styles'

export interface SessionConflictPopupProps {
  onConfirm: () => void
  onDiscard: () => void
  activeUser?: PresenceMessage
}

export const SessionConflictPopup = ({
  activeUser,
  onConfirm,
  onDiscard,
}: SessionConflictPopupProps): JSX.Element => {
  const analytics = useAnalytics()
  const { state } = useFlowBuilderSelector(ctx => ctx)
  const [hasEndedSession, setHasEndedSession] = useState(false)

  const isSameUser = () => {
    return activeUser?.clientId === state.flowBuilderUser?.id
  }

  const logUserOut = (): void => {
    analytics.trackEvent(TrackEventName.USER_ENDS_SESSION, {
      action: 'end_session',
      other_user_session: !isSameUser(),
    })
    setHasEndedSession(true)
    onConfirm()
  }

  const enterToEmptyFlow = (): void => {
    setHasEndedSession(true)
    onConfirm()
  }

  const discard = (): void => {
    analytics.trackEvent(TrackEventName.USER_ENDS_SESSION, {
      action: 'cancel',
      other_user_session: !isSameUser(),
    })
    onDiscard()
    postCloseFromFlowBuilderMessage(null)
  }

  if (!activeUser) {
    return (
      <Popup
        title='Flow Now Available'
        confirmButton={{ text: 'Start Editing', onClick: enterToEmptyFlow }}
        discardButton={{ text: 'Cancel', onClick: discard }}
        message='The user who was editing this flow has exited. You can now start editing.'
        canCloseOnClickOutside={false}
        closeButtonEnabled={false}
      />
    )
  }

  return (
    <Popup
      title={PopupType.SESSION_CONFLICT}
      confirmButton={{
        text: 'End their session',
        onClick: logUserOut,
        isHidden: hasEndedSession,
      }}
      discardButton={{
        text: 'Cancel',
        onClick: discard,
        isHidden: hasEndedSession,
      }}
      message='Another user is currently working on this flow. To start editing, end their session and their changes will be saved.'
      canCloseOnClickOutside={false}
      closeButtonEnabled={false}
    >
      <StyledEndSessionContainer>
        <StyledActiveUserInfoContainer
          color={!hasEndedSession ? COLORS.N900 : COLORS.N500}
        >
          <TextSmallBold>Currently active user</TextSmallBold>
          <TextSmall>{activeUser.data.userName}</TextSmall>
        </StyledActiveUserInfoContainer>
        {hasEndedSession && (
          <StyledSessionEndedContainer>
            <TextBodySmall>Saving changes...</TextBodySmall>
          </StyledSessionEndedContainer>
        )}
      </StyledEndSessionContainer>
    </Popup>
  )
}
