import { PresenceMessage } from 'ably'

import { TrackEventName, useAnalytics } from '../../analytics'
import {
  COLORS,
  HtIcon,
  Icon,
  Size,
  TextSmall,
  TextSmallBold,
} from '../../components/base'
import { Popup } from '../../components/popup/popup'
import { postCloseFromFlowBuilderMessage } from '../../hubtype-events'
import { PopupType } from '../../types'
import { ConnectionLostPopup } from './connection-lost'
import {
  StyledActiveUserInfoContainer,
  StyledEndSessionContainer,
  StyledSessionEndedTitle,
} from './session-styles'

export interface SessionEndedPopupProps {
  activeUser?: PresenceMessage
}

export const SessionEndedPopup = ({
  activeUser,
}: SessionEndedPopupProps): JSX.Element => {
  const analytics = useAnalytics()

  const discard = (): void => {
    analytics.trackEvent(TrackEventName.USER_KICKED_OUT)
    postCloseFromFlowBuilderMessage(null)
  }

  if (!activeUser) return <ConnectionLostPopup />

  return (
    <Popup
      title={
        <StyledSessionEndedTitle>
          <HtIcon icon={Icon.CIRCLE_EXCLAMATION} size={Size.MEDIUM} />
          {PopupType.SESSION_ENDED}
        </StyledSessionEndedTitle>
      }
      discardButton={{
        text: 'Close',
        onClick: discard,
      }}
      message='Your session has been ended by another user. All changes have been saved.'
      canCloseOnClickOutside={false}
      closeButtonEnabled={false}
    >
      <StyledEndSessionContainer>
        <StyledActiveUserInfoContainer color={COLORS.N900}>
          <TextSmallBold>Session ended by</TextSmallBold>
          <TextSmall>{activeUser.data.userName}</TextSmall>
        </StyledActiveUserInfoContainer>
      </StyledEndSessionContainer>
    </Popup>
  )
}
