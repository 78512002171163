import { memo } from 'react'

import { HtBtnType, HtButton, HtTooltip, Size } from '../../base'
import { InputAction } from '../constants'

interface ButtonProps {
  field: InputAction
  readOnly?: boolean
  isButtonDisabled?: boolean
  tooltip?: string
  onChange: (action: any) => void
}

export const ButtonWidget = memo((props: ButtonProps) => {
  return (
    <HtTooltip
      content={props.tooltip}
      isDisabled={!props.isButtonDisabled}
      placement='bottom left'
    >
      <HtButton
        type={HtBtnType.TERTIARY}
        size={Size.SMALL}
        disabled={props.readOnly || props.isButtonDisabled}
        onClick={() => props.onChange({ type: props.field.actionType })}
      >
        {props.field.label}
      </HtButton>
    </HtTooltip>
  )
})
