import { useRef } from 'react'
import { Overlay, Placement, useOverlayPosition, useTooltip } from 'react-aria'
import { TooltipTriggerState } from 'react-stately'

import { Arrow } from './arrow'
import { StyledTooltip } from './ht-tooltip-styles'

interface TooltipContentProps {
  state: TooltipTriggerState
  triggerRef: React.RefObject<HTMLElement>
  placement: Placement
  children: string
  showArrow?: boolean
}

export const TooltipContent = ({ state, ...props }: TooltipContentProps) => {
  const ref = useRef(null)
  const { tooltipProps } = useTooltip({}, state)

  const { arrowProps, placement } = useOverlayPosition({
    overlayRef: ref,
    targetRef: props.triggerRef,
    placement: props.placement,
    isOpen: state.isOpen,
    offset: props.showArrow ? 9 : 4,
  })

  return (
    <Overlay>
      <StyledTooltip {...tooltipProps} ref={ref} data-placement={placement}>
        {props.showArrow && <Arrow {...arrowProps} placement={placement} />}
        {props.children}
      </StyledTooltip>
    </Overlay>
  )
}
