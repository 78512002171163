import { useRef } from 'react'
import { Placement, useTooltipTrigger } from 'react-aria'
import { useTooltipTriggerState } from 'react-stately'

import { TooltipContent } from './content'

export interface TooltipProps {
  content?: string
  children: React.ReactElement
  placement?: Placement
  isDisabled?: boolean
  delay?: number
  closeDelay?: number
  showArrow?: boolean
}

export const HtTooltip = ({
  children,
  content,
  placement = 'bottom right',
  delay = 200,
  closeDelay = 200,
  showArrow = true,
  ...props
}: TooltipProps): JSX.Element => {
  const state = useTooltipTriggerState({ delay, ...props })
  const triggerRef = useRef(null)

  const { triggerProps, tooltipProps } = useTooltipTrigger(
    props,
    state,
    triggerRef
  )

  return (
    <span style={{ position: 'relative' }}>
      <div ref={triggerRef} {...triggerProps}>
        {children}
      </div>
      {state.isOpen && content && (
        <TooltipContent
          {...tooltipProps}
          state={state}
          triggerRef={triggerRef}
          placement={placement}
          showArrow={showArrow}
        >
          {content}
        </TooltipContent>
      )}
    </span>
  )
}
