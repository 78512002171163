import { styled } from 'styled-components'

import { COLORS, textSmall } from '../../../base'

export const StyledTransferSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid ${COLORS.N100};
`

export const TransferDisabledMessage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    ${textSmall}
    color: ${COLORS.N500};
  }
`

export const StyledWaringButton = styled.div`
  svg {
    --fa-secondary-color: ${COLORS.O500};
    --fa-primary-color: ${COLORS.WHITE};
    --fa-secondary-opacity: 1;
    height: 12px;
  }
`
